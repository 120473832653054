import React from 'react';
import '../Main/FrontBack.scss';

const sawDecoration = (props) => {
    return (
        <div className={props.class}>
            <svg width="171" height="39" viewBox="0 0 171 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M169.5 36.5L136 3L102.5 36.5L69 2.99999L35.5 36.5L2 2.99999" stroke="#FDC2D1" stroke-width="3"/>
            </svg>
        </div>
    )
}

export default sawDecoration;