import React from 'react';
import TechHeading from "./TechHeading";
import CursorIcon from '../other/CursorIcon';
import NodeIcon from "../other/technology/NodeIcon";
import ExpressIcon from "../other/technology/ExpressIcon";
import NextIcon from "../other/technology/NextIcon";
import PhpIcon from "../other/technology/PhpIcon";
import LaravelIcon from "../other/technology/LaravelIcon";
import WordpressIcon from "../other/technology/WordpressIcon";
import OpencartIcon from "../other/technology/OpencartIcon";

function BackEndBlock(props) {
    return (
        <div className="back-end-block" id='back-end'>
            <TechHeading class="right-red-blur" name="back-end" id="#back-end"/>
            <div className="container">
                <div className="back-end-block__main">
                    <CursorIcon class="heading-back-cursor" />
                    <p className="back-end-block__main__content">
                        Разработаем классический сайт на одной из популярных CMS в самые короткие сроки. Отличный старт для малого и среднего бизнеса, позволяющий быстро погрузить бизнес
                        в интернет.
                        Реализуем серверную часть для Ваших проектов. Напишем удобные Rest Api,
                        для дальнейшего использования в веб/мобильной разработке.
                    </p>
                    <div className="back-end-block__main__tech">
                        {/* <CrossFill class='back-blue-topCross'/>
                        <CrossEmpty class='back-blue-bottomCross'/> */}
                        <NodeIcon/>
                        {/* <SawDecoration class='saw-decoration-back'/> */}
                        <ExpressIcon/>
                        <NextIcon/>
                        <PhpIcon/>
                        <LaravelIcon/>
                        <h3 className='back-end-block__subtitle'>cms</h3>
                        <WordpressIcon/>
                        <OpencartIcon/>
                        {/* <EllipseDecoration class='ellipse-decoration-back'/> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackEndBlock;