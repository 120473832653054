import React from "react";
import './Footer.scss';

const yearNow = new Date().getFullYear();
const copyRight = () => {
    return(
        <div className="copyright">
            <div className="container">
                <div className="copyright__main">
                    <div className="copy-link">
                        <p>All rights reserved ©Alpairya</p>
                        
                        <p>{yearNow}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default copyRight;