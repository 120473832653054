import React, { useState } from "react";
import './Header.scss';
// import MainCross from '../other/MainCross';
import ButtonOne from "../other/ButtonOne";
// import LogoHeader from "./LogoHeader";
import ElipseSt from "../other/ElipseSt";
import BlockBlur from "../other/BlockBlur";
import CrossFill from "../other/CrossFill";
import CrossEmpty from "../other/CrossEmpty";
import SettingSite from "../other/SettingSite";
import Telegram from "../other/Telegram";
import PhoneCorp from "../other/PhoneCorp";
import Modal from "./Modal";
import { useInput } from "./Validation";
// import PhoneMask from "./PhoneMask";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import "./PhoneMask.scss";

function Header() {
    const [modalActive, setModalActive] = useState(false);

    const name = useInput('', {isEmpty: true, minLengthError: 2, maxLengthError: 15});
    const email = useInput('', {isEmpty: true, isEmail: true});
    const [phoneValue, setPhoneValue] = useState();

    return (
        <div className='header' id="main">
            <div className='container'>
                <div className='header__block'>
                    <CrossEmpty class="header__red-emptyCross cross-size-mob"/>
                    <CrossFill class="header__red-fillCross cross-size-mob"/>
                    <div className="btn-header">
                        <SettingSite/>
                        <Telegram/>
                        <ButtonOne onClick={() => setModalActive(true)} class="button-red">contact us</ButtonOne>
                    </div>
                    <PhoneCorp/>
                    <div className='header__block__top'>
                        <h1>Dev Alpairya</h1>
                    </div>
                    <ElipseSt class="star-elips-head"/>
                    <BlockBlur class="red-blur-circle blur-circle-one"/>
                    <BlockBlur class="gray-blur-elipse blur-circle-two"/>
                    <BlockBlur class="black-blur-circle blur-circle-three"/>
                    <CrossEmpty class="header__blue-emptyCross cross-size-mob"/>
                    <CrossFill class="header__blue-fillCross cross-size-mob"/>
                    <CrossEmpty class="header__blue-bottomEmptyCross cross-size-mob"/>
                </div>
            </div>
            <Modal active={modalActive} setActive={setModalActive}>
                <h3 className="modal__title">Leave your details and we'll contact you</h3>
                    <form className="modal__form">
                        <div className="modal__input-wrapper">
                            {(name.isDirty && name.isEmpty) && <div style={{color:'#ff004d'}}>field cannot be empty</div>}
                            {(name.isDirty && name.minLengthError) && <div style={{color:'#ff004d'}}>incorrect length</div>}
                            {(name.isDirty && name.maxLengthError) && <div style={{color:'#ff004d'}}>It's too long</div>}
                            <input onChange={e => name.onChange(e)}
                                    onBlur={e => name.onBlur(e)} 
                                    value={name.value.trim()} 
                                    className="modal__input" type="text" placeholder="Name"/>
                                                                
                            {(email.isDirty && email.isEmpty) && <div style={{color:'#ff004d'}}>field cannot be empty</div>}
                            {(email.isDirty && email.minLengthError) && <div style={{color:'#ff004d'}}>incorrect length</div>}
                            {(email.isDirty && email.emailError) && <div style={{color:'#ff004d'}}>incorrect email</div>}
                            <input onChange={e => email.onChange(e)} 
                                    onBlur={e => email.onBlur(e)} 
                                    value={email.value} 
                                    className="modal__input" type="email" placeholder="E-mail"/>
                         
                            {phoneValue ? (isValidPhoneNumber(phoneValue) ? undefined 
                            : <div style={{color:'#ff004d'}}>Invalid phone number</div>) 
                            : <div style={{color:'#ff004d'}}></div>}
                            <PhoneInput
                                className="modal__input"
                                international
                                defaultCountry="BY"
                                onChange={setPhoneValue}
                            />
                        </div>
                        <div className="modal__button-wrapper">
                            <ButtonOne disabled={!email.inputValid || !name.inputValid} 
                                        class="modal__button"
                                        type="submit">Submit</ButtonOne>
                            <ButtonOne onClick={() => setModalActive(false)} 
                                        type="button"
                                        class="modal__button-cancel">Cancel</ButtonOne>
                        </div>
                    </form>
            </Modal>
        </div>
    );
}

export default Header;