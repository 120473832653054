import React from 'react';
import '../../App.scss';

const openCart = (props) => {
    return (
        <div className={props.class}>
            <svg width="183" height="123" viewBox="0 0 183 123" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M74.7629 4.58759L96.8574 23.5513L126.777 0.264648L126.397 26.9643L167.085 15.2547L144.35 41.2134L182.888 44.7984L145.017 61.7745L169.169 79.516L128.186 82.1196L130.284 108.385L99.2004 95.7893L78.5788 122.239L67.2635 98.4436L30.4692 116.68L42.5149 89.2398L1.22968 93.4735L32.8118 71.0999L0.143555 59.9864L41.2351 49.7833L27.5557 26.851L65.1104 32.0578L74.7629 4.58759Z" fill="url(#paint0_linear_1_376)"/>
                <path d="M105.019 76.1081C106.156 78.0766 105.474 80.576 103.489 81.7224C101.503 82.8688 98.9823 82.2182 97.8458 80.2497C96.7093 78.2812 97.4051 75.7527 99.3829 74.6108C101.376 73.4599 103.883 74.1396 105.019 76.1081ZM83.0348 84.0494C81.0492 85.1958 80.3813 87.7185 81.5134 89.6792C82.6454 91.64 85.155 92.3074 87.1407 91.161C89.1263 90.0146 89.8233 87.5062 88.6868 85.5377C87.5503 83.5691 85.0204 82.903 83.0348 84.0494ZM93.7996 49.8239C70.0818 63.5173 63.4636 66.0105 53.1545 62.999C58.1768 65.4629 64.0266 72.1693 88.9145 57.3127C114.848 41.821 102.985 58.1212 102.957 68.8023C110.121 47.372 117.517 36.1304 93.7996 49.8239Z" fill="#FDC2D1"/>
                <defs>
                <linearGradient id="paint0_linear_1_376" x1="97.2133" y1="88.3737" x2="-31.8954" y2="33.4167" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1400F5" className='stop-color1'/>
                <stop offset="1" stop-color="#151C22" className='stop-color2'/>
                </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default openCart;