import React from 'react';
import '../Main/FrontBack.scss';

const ellipseDecoration = (props) => {
    return (
        <div className={props.class}>
            <svg width="137" height="108" viewBox="0 0 137 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M131.13 91.6645C125.291 101.364 113.144 106.334 97.7623 106.149C82.4224 105.966 64.173 100.633 46.7061 90.1171C29.2392 79.6009 15.9875 65.9676 8.64743 52.4965C1.28751 38.989 -0.00381533 25.928 5.8356 16.229C11.675 6.53006 23.8223 1.55992 39.2036 1.74419C54.5435 1.92797 72.7929 7.26032 90.2598 17.7765C107.727 28.2927 120.978 41.926 128.318 55.3971C135.678 68.9045 136.97 81.9655 131.13 91.6645Z" stroke="#F1DFFF" stroke-width="3"/>
            </svg>       
        </div>
    )
}

export default ellipseDecoration;