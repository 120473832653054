import React from 'react';
import SawDecoration from '../SawDecoration';

function NodeIcon() {
    return (
        <div className='icon node-bg node'>
            <SawDecoration class='saw-decoration-back'/>
        </div>
    );
}

export default NodeIcon;