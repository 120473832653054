import React from 'react';
import '../../App.scss';
import { useTheme } from "../theme/Dark";

function SettingSite() {

    const { theme, setTheme } = useTheme();

    const onClickThemeOne = () => {
        setTheme('theme-one')
    }

    const onClickThemeTwo = () => {
        setTheme('theme-two')
    }

    const onClickThemeThree = () => {
        setTheme('theme-three')
    }

    const onClickThemeFour = () => {
        setTheme('theme-four')
    }

    const onClickThemeFive = () => {
        setTheme('theme-five')
    }

    return (
        <div className="setting-site">
            <svg className='setting-site-icon' width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className='setting-site-drop' fill-rule="evenodd" clip-rule="evenodd" d="M34 33C35.0609 33 36.0783 32.5786 36.8284 31.8284C37.5786 31.0783 38 30.0609 38 29C38 27.527 36.667 25.527 34 23C31.333 25.527 30 27.527 30 29C30 30.0609 30.4214 31.0783 31.1716 31.8284C31.9217 32.5786 32.9391 33 34 33Z" fill="#FF004D"/>
                <path d="M17.854 1.504L21.389 5.04" stroke="#FF004D" stroke-width="2" stroke-linecap="round"/>
                <path d="M20.682 4.33301L5.125 19.889L16.44 31.203L31.996 15.646L20.682 4.33301Z" stroke="#FF004D" stroke-width="2" stroke-linejoin="round"/>
                <path d="M9 16.073L25.961 21.65" stroke="#FF004D" stroke-width="2" stroke-linecap="round"/>
                <path d="M1 39H41" stroke="#FF004D" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <svg onClick={onClickThemeOne} className='setting-site-theme1' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_41" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                <circle cx="20" cy="20" r="20" fill="#FF004D"/>
                </mask>
                <g mask="url(#mask0_1_41)">
                <circle cx="20" cy="20" r="20" fill="#FF004C"/>
                <path d="M36.3637 0L39.4678 11.2463L48.1595 3.46356L44.6906 14.6028L56.2102 12.7546L47.2697 20.2501L57.9598 24.9232L46.3861 26.3953L52.8527 36.1061L42.3205 31.0873L42.5105 42.7526L36.3637 32.8364L30.2168 42.7526L30.4068 31.0873L19.8746 36.1061L26.3412 26.3953L14.7675 24.9232L25.4576 20.2501L16.5171 12.7546L28.0367 14.6028L24.5679 3.46356L33.2595 11.2463L36.3637 0Z" fill="#1400F5"/>
                </g>
            </svg>

            <svg onClick={onClickThemeTwo} className='setting-site-theme2' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_49" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <circle cx="20" cy="20" r="20" fill="#FF004D"/>
                </mask>
                <g mask="url(#mask0_1_49)">
                    <circle cx="20" cy="20" r="20" fill="#D941FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2886 20.578C19.4386 17.6887 22.2653 15.9766 22.9331 16.6444C23.6008 17.3121 21.8887 20.1388 18.9994 23.2888C21.8886 26.4387 23.6007 29.2654 22.933 29.9331C22.2652 30.6009 19.4386 28.8888 16.2886 25.9996C13.1387 28.8888 10.3121 30.6008 9.64436 29.9331C8.97664 29.2653 10.6887 26.4387 13.5778 23.2888C10.6886 20.1388 8.97653 17.3122 9.64426 16.6444C10.312 15.9767 13.1387 17.6888 16.2886 20.578Z" fill="#310FB9"/>
                </g>
            </svg>

            <svg onClick={onClickThemeThree} className='setting-site-theme3' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_56" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <circle cx="20" cy="20" r="20" fill="#FF004D"/>
                </mask>
                <g mask="url(#mask0_1_56)">
                    <circle cx="20" cy="20" r="20" fill="#119DC9"/>
                    <path d="M28.5961 -21L31.7003 -9.75369L40.3919 -17.5364L36.9231 -6.39719L48.4426 -8.24542L39.5021 -0.749868L50.1922 3.92323L38.6186 5.3953L45.0852 15.1061L34.553 10.0873L34.743 21.7526L28.5961 11.8364L22.4492 21.7526L22.6392 10.0873L12.107 15.1061L18.5736 5.3953L7 3.92323L17.6901 -0.749868L8.74959 -8.24542L20.2691 -6.39719L16.8003 -17.5364L25.4919 -9.75369L28.5961 -21Z" fill="#E7006F"/>
                </g>
            </svg>

            <svg onClick={onClickThemeFour} className='setting-site-theme4' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_4_61" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                    <circle cx="20" cy="20" r="20" fill="#FF004D"/>
                </mask>
                <g mask="url(#mask0_4_61)">
                    <circle cx="20" cy="20" r="20" fill="#52FF00"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2886 13.578C26.4386 10.6887 29.2653 8.97663 29.9331 9.64436C30.6008 10.3121 28.8887 13.1388 25.9994 16.2888C28.8886 19.4387 30.6007 22.2654 29.933 22.9331C29.2652 23.6009 26.4386 21.8888 23.2886 18.9996C20.1387 21.8888 17.3121 23.6008 16.6444 22.9331C15.9766 22.2653 17.6887 19.4387 20.5778 16.2888C17.6886 13.1388 15.9765 10.3122 16.6443 9.64444C17.312 8.97671 20.1387 10.6888 23.2886 13.578Z" fill="#1400F5"/>
                </g>
            </svg>

            <svg onClick={onClickThemeFive} className='setting-site-theme5' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_4_67" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                <circle cx="20" cy="20" r="20" fill="#FF004D"/>
                </mask>
                <g mask="url(#mask0_4_67)">
                <circle cx="20" cy="20" r="20" fill="#61A8BE"/>
                <path d="M7.81818 9L10.9224 20.2463L19.614 12.4636L16.1452 23.6028L27.6647 21.7546L18.7242 29.2501L29.4143 33.9232L17.8407 35.3953L24.3073 45.1061L13.7751 40.0873L13.9651 51.7526L7.81818 41.8364L1.67129 51.7526L1.8613 40.0873L-8.6709 45.1061L-2.20431 35.3953L-13.7779 33.9232L-3.08785 29.2501L-12.0283 21.7546L-0.508804 23.6028L-3.97762 12.4636L4.714 20.2463L7.81818 9Z" fill="#FF0000"/>
                </g>
            </svg>
        </div>
    );
}

export default SettingSite;