import React from 'react';

function ElipseSt(props) {
    return (
        <svg className={props.class} width="190" height="115" viewBox="0 0 190 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M95.0001 -0.000213623L111.434 24.0358L146.361 9.28645L139.083 34.9778L181.415 34.198L152.736 53.388L189.033 66.8252L148.059 73.4211L166.796 96.8091L126.536 88.7168L121.765 114.63L95.0001 94.4188L68.2355 114.63L63.4645 88.7168L23.2039 96.8091L41.9413 73.4211L0.967087 66.8252L37.2638 53.388L8.58508 34.198L50.9172 34.9778L43.6392 9.28645L78.5667 24.0358L95.0001 -0.000213623Z" fill="url(#paint0_linear_1_293)"/>
            <defs>
                <linearGradient id="paint0_linear_1_293" x1="95.0001" y1="86.7411" x2="-15.4854" y2="0.241024" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1400F5" className='star-stop1'/>
                    <stop offset="1" stop-color="#151C22" className='star-stop2'/>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default ElipseSt;