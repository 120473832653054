import React from 'react';
import SawDecoration from '../SawDecoration';

function CssIcon() {
    return (
        <div className='icon css-bg css'>
            <SawDecoration class="saw-decoration"/>
        </div>
    );
}

export default CssIcon;