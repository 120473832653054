import React from 'react';
import Marquee from "react-fast-marquee";
import '../../App.scss'

function Cases(props) {
    return (
        <div className="cases-block" id='cases'>
            <Marquee>
                <span>cases</span><span>cases</span><span>cases</span><span>cases</span><span>cases</span><span>cases</span><span>cases</span><span>cases</span><span>cases</span>
            </Marquee>
        </div>
    );
}

export default Cases;