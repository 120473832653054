import React from 'react';
import './FrontBack.scss'
import HtmlIcon from "../other/technology/HtmlIcon";
import CssIcon from "../other/technology/CssIcon";
import JsIcon from "../other/technology/JsIcon";
import ScssIcon from "../other/technology/ScssIcon";
import ReactIcon from "../other/technology/ReactIcon";
import VueIcon from "../other/technology/VueIcon";
import TechHeading from "./TechHeading";
import CursorIcon from "../other/CursorIcon";

function FrontEndBlock(props) {
    return (
        <div className="front-end-block" id='front-end'>
            <TechHeading class="left-red-blur" name="front-end" id="#front-end" />
            <div className="container">
                <div className="front-end-block__main">
                    <CursorIcon class="heading-front-cursor" />
                    <p className="front-end-block__main__content">
                        Разработаем для Вас высоко функциональное веб/мобильное приложение. Поможем 
                        оптимизировать и автоматизировать рутинные процессы. Уделим большое внимание 
                        визуальной части, проработке интерфейсов, удобству работы пользователей.
                        Подберем и реализуем индивидуальное решение под Ваши нужды.
                        Вдохнем новую жизнь в Ваши старые проекты!</p>
                    <div className="front-end-block__main__tech">
                        <HtmlIcon/>
                        <CssIcon/>
                        <JsIcon/>
                        <ScssIcon/>
                        <ReactIcon/>
                        <VueIcon/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FrontEndBlock;
