import React from 'react';
import '../Main/FrontBack.scss';

function TechHeading(props) {
    return (
        <div className={'heading-tech' + ' ' + props.class}>
            <div className="container">
                <h2>{props.name}</h2>
                <div className="blur-for-heading">

                </div>
            </div>
        </div>
    );
}

export default TechHeading;