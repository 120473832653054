import React from 'react';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import CopyRight from "./components/Footer/CopyRight";
import Cases from "./components/other/Cases";
import FrontEndBlock from "./components/Main/FrontEndBlock";
import BackEndBlock from "./components/Main/BackEndBlock";
import Portfolio from "./components/Main/Portfolio";
import ScrollToTopButton from "./components/other/ScrollToTopButton"
import './App.scss';

function App() {
    return (
        <div className="App">
            <Header/>
            <FrontEndBlock/>
            <BackEndBlock/>
            <Cases/>
            <Portfolio/>
            <Footer/>
            <CopyRight/>
            <ScrollToTopButton/>
        </div>
    );
}

export default App;
