import React from "react";
import "../../scss/Mixins.scss";
import IconInstagram from "./technology/IconInstargam";

const circleOne = (props) => {
    return(
    <svg width={props.width} height={props.height} className={props.class} viewBox="0 0 147 147" xmlns="http://www.w3.org/2000/svg">
        <circle cx="73.5" cy="73.5" r="73.5"/>
        <path d="M88.6792 47.0351H112.634V52.9175H88.6792V47.0351ZM64.6762 70.3096C69.9715 67.7452 72.7452 63.8438 72.7452 57.8155C72.7452 45.8926 63.9797 43 53.8574 43H26V102.845H54.6379C65.3726 102.845 75.4469 97.6192 75.4469 85.4654C75.4469 77.9544 71.9407 72.4001 64.6762 70.3096ZM38.9921 53.2092H51.1797C55.8747 53.2092 60.0893 54.534 60.0893 60.0518C60.0893 65.1321 56.8113 67.1739 52.1644 67.1739H38.9921V53.2092ZM52.8608 92.6848H38.9801V76.2042H53.137C58.8525 76.2042 62.4668 78.6228 62.4668 84.7484C62.4668 90.7766 58.1561 92.6848 52.8608 92.6848ZM122 81.8071C122 68.9849 114.591 58.3017 101.191 58.3017C88.1629 58.3017 79.3013 68.2313 79.3013 81.2481C79.3013 94.7388 87.6946 104 101.191 104C111.409 104 118.026 99.3451 121.208 89.4154H110.845C109.716 93.1223 105.129 95.0669 101.563 95.0669C94.6709 95.0669 91.0687 90.9833 91.0687 84.0434H121.916C121.952 83.3264 122 82.5728 122 81.8071ZM91.0687 76.5324C91.4409 70.8322 95.1992 67.2712 100.819 67.2712C106.726 67.2712 109.68 70.7836 110.197 76.5324H91.0687Z" fill="#FDC2D1"/>
    </svg>
)
}

export default circleOne;


