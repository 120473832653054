import React from "react";
import './Footer.scss';
import StarOne from "../other/StarOne";
import CircleOne from "../other/CircleOne";
import EllipseOne from "../other/EllipseOne";

function Footer(){

    return(
        <div className="footer">
            <div className="container">
                <div className="footer__main">
                    <div className="footer__main__header">
                        <div className="footer__links">
                            <a href="#main">main<span>/</span></a>
                            <a href="#front-end">front-end<span>/</span></a>
                            <a href="#back-end">back-end<span>/</span></a>
                            <a href="#cases">cases</a>
                        </div>
                    </div>
                    <div className="footer__main__social">
                        <EllipseOne/>
                        <CircleOne width="147px" height="147px" class="redStar footer-be"/>
                        <StarOne width="180px" height="180px" class="redStar footer-insta"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;