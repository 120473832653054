import React from 'react';
import '../Main/FrontBack.scss';

const cursorIcon = (props) => {
    return (
        <div className={props.class}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.97781 21.6572L1.38271 2.08387C1.23005 1.25274 2.1132 0.618683 2.85189 1.02906L21.0943 11.1637C21.9109 11.6173 21.7149 12.8434 20.7975 13.0198L10.9721 14.9093C10.6737 14.9667 10.4176 15.1567 10.2761 15.4256L6.84628 21.9423C6.40401 22.7826 5.14935 22.5912 4.97781 21.6572Z" fill="#F1DFFF" stroke="#F1DFFF"/>
            </svg>
        </div>
    )
}

export default cursorIcon;