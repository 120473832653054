import React from 'react';
import CrossFill from '../CrossFill';
import CrossEmpty from '../CrossEmpty';

function NextIcon() {
    return (
        <div className='icon next-bg next'>
            <CrossFill class='back-blue-topCross'/>
            <CrossEmpty class='back-blue-bottomCross'/>
        </div>
    );
}

export default NextIcon;