import React from 'react';
import '../../App.scss';

const phoneCorp = () => {
    return (
        <div className='phone-corp'>
            <a href="tel:+375293401529" title='+375(29)340-15-29' className='phone-link'>+375(29)340-15-29</a>
        </div>
    )
}

export default phoneCorp;