import React from "react";
import "../../scss/Mixins.scss";
import IconInstagram from "./technology/IconInstargam";

const starOne = (props) => {
    return(
        <svg width={props.width} height={props.height} class={props.class} viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
            <path d="M90 0L103.906 38.1008L135 12.0577L127.993 52.0072L167.942 45L141.899 76.0937L180 90L141.899 103.906L167.942 135L127.993 127.993L135 167.942L103.906 141.899L90 180L76.0937 141.899L45 167.942L52.0072 127.993L12.0577 135L38.1008 103.906L0 90L38.1008 76.0937L12.0577 45L52.0072 52.0072L45 12.0577L76.0937 38.1008L90 0Z"/>
            <IconInstagram />
        </svg>
    )
}

export default starOne;


