import React from "react";
// import PortfolioCard from "./PortfolioCard";
import './Portfolio.scss';
import CrossFill from '../other/CrossFill';
import CrossEmpty from "../other/CrossEmpty";
import OpenCart from "../other/OpenCartBg";
import VueBg from "../other/VueBg";
import BlockBlur from "../other/BlockBlur";

function Portfolio() {
    return (
        <div className="portfolio">
            <div className="container">
                <div className="portfolio__block">
                    <CrossEmpty class="top-crossEmpty"/>
                    <CrossFill class="top-crossFill"/>
                    <CrossEmpty class="bottom-crossEmpty"/>
                    <BlockBlur class="portfolioBlue-blur-circle blue-blur"/>
                    <BlockBlur class="red-blur-portfolio red-blur-one"/>
                    <BlockBlur class="red-blur-portfolio red-blur-two"/>
                    <BlockBlur class="red-blur-portfolioSmall red-blur-three"/>
                    <BlockBlur class="black-blur-portfolio black-blur-one"/>
                    <BlockBlur class="black-blur-portfolioSmall black-blur-two"/>
                    <div className="card card-top">
                        <OpenCart class="opencart-bg"/>
                        <div className="card__head">
                            <h3 className="card-title">lotti bistrot</h3>
                        </div>
                        <div className="card__body">
                            <p className="card-descr">
                                Концепция айдентики и логотипа для итальянского кафе Lotti bistrot.
                                <br/>
                                <br/>
                                Lotti bistrot – ресторан современной итальянской кухни. Меню в формате comfort food с несколькими видами пасты, римской пиццы, крудо из рыбы и морепродуктов.
                            </p>
                            <a className="card-link" href="https://www.behance.net/">https://www.behance.net</a>
                        </div>
                        <div className="card__image img-top"></div>
                    </div>

                    <div className="card">
                        <VueBg class="vue-bg"/>
                        <div className="card__head">
                            <h3 className="card-title card-title-2">Гайд: как уснуть быстро и глубоко?</h3>
                        </div>
                        <div className="card__body">
                            <p className="card-descr">
                                Привет! Мы сделали этот пост для тех, кто не может уснуть!
                                <br/>
                                <br/>
                                Научная информация взята из видео: 
                                <a href="https://youtu.be/-rkASi7bUUU?list=PLZ_8rwqKYCz-vKeqZtBxsjK544ozeYTyq" className="descr-link">
                                    https://youtu.be/-rkASi7bUUU?list=PLZ_8rwqKYCz-vKeqZtBxsjK544ozeYTyq
                                </a>
                            </p>
                            <a className="card-link" href="https://www.behance.net/">https://www.behance.net</a>
                        </div>
                        <div className="card__image img-bottom"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;