import React from "react";
import { useState, useEffect } from "react";

function ScrollToTopButton() {
    const [scrollToTopButton, setScrollToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setScrollToTopButton(true);
            } else {
                setScrollToTopButton(false)
            }
        });
    }, []);

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <>
            {scrollToTopButton && (
                <button className="scroll-to-top" onClick={scrollUp}>

                </button>
            )}
        </>
    )
}

export default ScrollToTopButton;