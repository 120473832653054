import React from 'react';
import '../../App.scss';

const vueBg = (props) => {
    return (
        <div className={props.class}>
            <svg width="183" height="122" viewBox="0 0 183 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M74.7629 4.32294L96.8574 23.2866L126.777 0L126.397 26.6997L167.085 14.99L144.35 40.9487L182.888 44.5338L145.017 61.5098L169.169 79.2514L128.186 81.855L130.284 108.12L99.2004 95.5247L78.5788 121.975L67.2635 98.179L30.4692 116.416L42.5149 88.9751L1.22968 93.2089L32.8118 70.8352L0.143555 59.7217L41.2351 49.5186L27.5557 26.5863L65.1104 31.7932L74.7629 4.32294Z" fill="url(#paint0_linear_1_384)"/>
                <path d="M93.8291 48.1933L87.2989 50.7511L85.5834 60.3942L78.4559 54.2148L63.489 60.0771L95.5934 85.9501L101.585 45.1555L93.8291 48.1933ZM69.339 60.9898L73.9645 59.178L90.3708 72.6166L93.2854 51.6102L97.9109 49.7985L93.4173 80.3945L69.339 60.9898Z" fill="#FDC2D1"/>
                <defs>
                <linearGradient id="paint0_linear_1_384" x1="97.2133" y1="88.109" x2="-31.8954" y2="33.1521" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1400F5" className='stop-color1'/>
                <stop offset="1" stop-color="#151C22" className='stop-color2'/>
                </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default vueBg;