import React from 'react';

function BlockBlur(props) {
    return (
        <div className={props.class}>

        </div>
    );
}

export default BlockBlur;