import React from 'react';
import '../../App.scss';

const telegram = () => {
    return (
        <div className='telegram'>
            <a href='https://t.me/' target={'_blank'} className='telegram-link'>
                {/* <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='path1' d="M43.5 22.5C43.5 34.0972 34.0972 43.5 22.5 43.5C10.9028 43.5 1.5 34.0972 1.5 22.5C1.5 10.9028 10.9028 1.5 22.5 1.5C34.0972 1.5 43.5 10.9028 43.5 22.5Z" stroke="#FF004D" stroke-width="3"/>
                    <path className='path2' fill-rule="evenodd" clip-rule="evenodd" d="M23.3062 16.6108C21.1181 17.5201 16.7437 19.4045 10.185 22.262C9.11998 22.6857 8.56123 23.1001 8.5106 23.5051C8.42435 24.1914 9.28311 24.4614 10.4494 24.827C10.6087 24.8776 10.7737 24.9282 10.9425 24.9845C12.0919 25.3576 13.6369 25.7945 14.4394 25.8114C15.1687 25.8264 15.9825 25.5264 16.8806 24.9114C23.0081 20.7733 26.1712 18.6826 26.37 18.6376C26.5106 18.6057 26.7056 18.5645 26.8369 18.6826C26.9681 18.7989 26.955 19.0201 26.9419 19.0801C26.8556 19.442 23.4919 22.5714 21.7481 24.1914C21.2044 24.6957 20.82 25.0539 20.7412 25.1364C20.565 25.3182 20.385 25.4926 20.2125 25.6595C19.1437 26.687 18.345 27.4595 20.2575 28.7195C21.1762 29.3251 21.9112 29.8257 22.6444 30.3245C23.445 30.8701 24.2437 31.4139 25.2787 32.0926C25.5412 32.2651 25.7925 32.4432 26.0381 32.6176C26.97 33.2832 27.8081 33.8795 28.8431 33.7857C29.4431 33.7295 30.0656 33.1651 30.3806 31.4795C31.125 27.4932 32.5912 18.8607 32.9306 15.302C32.9513 15.0065 32.9387 14.7097 32.8931 14.417C32.8658 14.1806 32.7507 13.9631 32.5706 13.8076C32.3025 13.5882 31.8862 13.5414 31.6987 13.5451C30.8531 13.5601 29.5556 14.012 23.3062 16.6108Z" fill="#FF004D"/>
                </svg> */}
            </a>
        </div>
    )
}

export default telegram;