import React from 'react';
import EllipseDecoration from '../EllipseDecoration';

function ScssIcon() {
    return (
        <div className='icon scss-bg scss'>
            <EllipseDecoration class="ellipse-decoration"/>
        </div>
    );
}

export default ScssIcon;