import React from 'react';
import EllipseDecoration from '../EllipseDecoration';

function OpencartIcon() {
    return (
        <div className='icon opencart-bg opencart'>
            <EllipseDecoration class='ellipse-decoration-back'/>
        </div>
    );
}

export default OpencartIcon;